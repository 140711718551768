import React, { useState, useEffect, Fragment } from 'react';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import Cookies from 'js-cookie';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';

import { useBackground } from '../../../hooks/use-background';
import { getImage } from '../../../helpers';
import {
    getShopifyCustomer,
    getCustomerSubscriptions,
    cancelSubscription,
} from '~/helpers/requests/customer-requests';
import { OrderList } from '~/components/AccountPages';
import { Heading, Subheading, BodyText, BodyTextAlt } from '~/components/.base/headings';
import { FlexBox, Row, Col } from '~/components/.base/containers';
import { WhiteButton } from '~/components/.base/buttons';
import { BackgroundDiv, PageContainerEcomm } from '~/components/.base/containers';
import LogOutIcon from '~/images/icons/accountpage/logout.svg';
import '../account.scss';
import './dashboard.scss';

const LogoutButton = styled(FlexBox)`
    &:hover,
    &:focus {
        outline: none;
        ${WhiteButton} {
            @media screen and (min-width: 768px) {
                background-color: #d4212c;
                color: #fff;
                border-color: #d4212c;
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
`;

const AccountPage = ({ location }) => {
    const backgroundImages = useBackground().edges;

    const [customer, setCustomer] = useState({});
    const [subscriptions, setSubscriptions] = useState([]);
    const [refetch, setRefetch] = useState(false);
    useEffect(() => {
        const accessToken = Cookies.get('KEPT_SESS');
        if (!accessToken) window.location.href = '/account/login';
        getShopifyCustomer(accessToken)
            .then(res => {
                setCustomer(res.data.data.customer);
            })
            .catch(err => console.log(err));
    }, [refetch]);
    useEffect(() => {
        if (customer && customer.id) {
            const accessToken = Cookies.get('KEPT_SESS');
            const customerId = btoa(customer.id);

            getCustomerSubscriptions(accessToken, customerId)
                .then(res => {
                    console.log(res);
                    setSubscriptions(
                        res.data.filter(sub => sub.interval > 0 && sub.subscriptionActive)
                    );
                })
                .catch(err => console.log(err));
        }
    }, [customer, refetch]);

    const cancelSubscriptionFn = e => {
        if (e.target) {
            console.log(e.target);
            const { chargeid } = e.target.dataset;
            console.log(chargeid);
            cancelSubscription(chargeid)
                .then(res => {
                    console.log(res);
                    setRefetch('subscription');
                })
                .catch(err => console.log(err));
        }
    };
    const logOut = () => {
        Cookies.remove('KEPT_SESS');
        window.location.href = '/';
    };
    return (
        <PageContainerEcomm className="account account-dashboard">
            <BackgroundDiv
                left="50px"
                top="150px"
                z="-1"
                css={css`
                    width: 747px;
                    max-width: 100%;
                `}
            >
                <Image fluid={getImage(backgroundImages, 'blurred_smudge')} alt="" />
            </BackgroundDiv>
            <SEO title="Account" />
            <Criteo />
            <DeriveHeaderClasses location={location} />
            <header className="header">
                <FlexBox justify="center" className="header-items">
                    <Heading as="h1" md="0" align="center">
                        <span className="red">My account</span>
                    </Heading>
                    <LogoutButton
                        as="button"
                        justify="center"
                        align="center"
                        onClick={logOut}
                        className="log-out"
                    >
                        <BodyTextAlt className="logout__label" as="span" md="0 .75rem 0 0">
                            Logout
                        </BodyTextAlt>
                        <WhiteButton as="span" className="no-stroke" w="40px" h="40px" pd="0">
                            <LogOutIcon />
                        </WhiteButton>
                    </LogoutButton>
                </FlexBox>
            </header>
            <Row className="account-main">
                <Col className="account-orders" size={4}>
                    <OrderList
                        customer={customer}
                        subscriptions={subscriptions}
                        cancelSubscriptionFn={cancelSubscriptionFn}
                    />
                </Col>
                <Col className="account-details" size={1}>
                    <Subheading as="h2">Account Details</Subheading>
                    {customer && customer.defaultAddress ? (
                        <Fragment>
                            <BodyText>
                                {`${customer.defaultAddress.firstName} ${customer.defaultAddress.lastName}`}
                                <br />
                                {customer.defaultAddress.address1}
                                <br />
                                {customer.defaultAddress.address2 ? (
                                    <Fragment>
                                        {customer.defaultAddress.address2}
                                        <br />
                                    </Fragment>
                                ) : null}
                                {`${customer.defaultAddress.city}, ${
                                    customer.defaultAddress.provinceCode
                                        ? `${customer.defaultAddress.provinceCode}, `
                                        : null
                                }${customer.defaultAddress.countryCodeV2}`}
                            </BodyText>
                        </Fragment>
                    ) : (
                        <BodyText>No Addresses Found.</BodyText>
                    )}
                    <WhiteButton as={Link} to="/account/addresses">
                        View Addresses
                    </WhiteButton>
                </Col>
            </Row>
        </PageContainerEcomm>
    );
};

export default AccountPage;
